import * as React from 'react';
import { useLanguage } from '../../locale';
import { Image, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { s } from 'react-native-wind';
import { Paragraph } from '../Paragraph';
import italianFlagIcon from '../../../assets/italian-flag-icon.png';
import usaFlagIcon from '../../../assets/usa-flag-icon.png';
import { SupportedLocale } from '../../locale/localizationContext';
import SelectLanguageModal from './SelectLanguageModal';
import { useStorageProvider } from '../../context/StorageProviderContext/useStorageProvider';
import { StorageKeys } from '../../services/StorageProvider/IStorageProvider';

type SelectAppLanguageProps = {
  style?: StyleProp<ViewStyle>;
};

const SelectAppLanguage: React.FC<SelectAppLanguageProps> = ({ style }) => {
  const [t, setLocale, currentLocale] = useLanguage();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const storage = useStorageProvider();

  const flagSrc = React.useMemo(() => {
    if (currentLocale === SupportedLocale.En) {
      return usaFlagIcon;
    } else {
      return italianFlagIcon;
    }
  }, [currentLocale]);

  const onLocaleChange = React.useCallback(
    async (newLocale: SupportedLocale) => {
      await storage.setItem(StorageKeys.SELECTED_LANGUAGE, newLocale);
      setLocale(newLocale);
    },
    [setLocale, storage]
  );

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <TouchableOpacity
        onPress={openModal}
        style={[
          s`items-center flex-row rounded-2xl bg-card p-4 border border-text-secondary`,
          style,
        ]}
      >
        <Paragraph style={s`flex-1`}>
          {t('shared.selectAppLanguage.label')}
        </Paragraph>
        <Image
          source={flagSrc}
          style={s`rounded-full resize-contain w-8 h-8`}
        />
      </TouchableOpacity>
      <SelectLanguageModal
        isOpen={isModalOpen}
        onLangSelect={onLocaleChange}
        initLang={currentLocale}
        close={closeModal}
      />
    </>
  );
};

export default SelectAppLanguage;
