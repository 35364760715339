import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { Config } from '../config';

type TeamsIconProps = {
  size?: number;
  color?: string;
};

export const TeamsIcon: React.FC<TeamsIconProps> = ({ color, size }) => {
  return (
    <Ionicons
      name="people-outline"
      size={size || 24}
      color={color || Config.colorTextPrimary}
    />
  );
};
