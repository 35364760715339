import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  CancelTokenSource,
  AxiosRequestHeaders,
} from 'axios';
import { IErrorHandler } from '../ErrorHandler/IErrorHandler';
import {
  DeleteRequestConfig,
  GetRequestConfig,
  IHttpClient,
  IAuthConsumer,
  PatchRequestConfig,
  PostRequestConfig,
  PrepareRequestParams,
  PutRequestConfig,
  RefreshAuthDelegate,
} from './IHttpClient';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { JwtTokenInfo } from '../../models/general.models';
import axiosRetry, { exponentialDelay, isNetworkError } from 'axios-retry';
import { recordError } from '../../portability/services/Firebase/Crashlytics/Crashlytics';

export type HttpClientInitParams = {
  baseUrl: string;
  errorHandler: IErrorHandler;
  axiosInstance?: AxiosInstance;
  accessToken?: string | null;
  refreshToken?: string | null;
};

export class UmmyHttpClient implements IHttpClient, IAuthConsumer {
  private readonly axiosInstance: AxiosInstance;
  private refreshAuthDelegate: RefreshAuthDelegate | undefined;
  private readonly cancelToken: CancelTokenSource;

  private errorInterceptor: null | number = null;

  private accessToken: string | undefined;
  private refreshToken: string | undefined;

  constructor({
    baseUrl,
    axiosInstance,
    errorHandler,
    accessToken,
    refreshToken,
  }: HttpClientInitParams) {
    this.cancelToken = axios.CancelToken.source();
    this.axiosInstance =
      axiosInstance ??
      axios.create({
        baseURL: baseUrl,
        cancelToken: this.cancelToken.token,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 10000,
      });

    axiosRetry(this.axiosInstance, {
      retries: 2,
      retryDelay: exponentialDelay,
      retryCondition: isNetworkError,
    });

    this.setupErrorHandler(errorHandler);
    if (accessToken && refreshToken) this.setupAuth(accessToken, refreshToken);
  }

  public get = async <T>(
    url: string,
    configs: GetRequestConfig = { isAuthorized: true }
  ): Promise<T> => {
    const { headers: preparedHeaders } = await this.prepareRequest(configs);
    const res = await this.axiosInstance.get<T>(url, {
      ...configs,
      headers: { ...configs?.headers, ...preparedHeaders },
    });
    return res.data;
  };

  public post = async <T, P>(
    url: string,
    configs: PostRequestConfig<P> = { isAuthorized: true }
  ): Promise<T> => {
    const { headers: preparedHeaders } = await this.prepareRequest(configs);
    const res = await this.axiosInstance.post<T>(url, configs?.body, {
      headers: { ...configs?.headers, ...preparedHeaders },
    });

    return res.data;
  };

  public put = async <T, P>(
    url: string,
    configs: PutRequestConfig<P> = { isAuthorized: true }
  ): Promise<T> => {
    const { headers: preparedHeaders } = await this.prepareRequest(configs);
    const res = await this.axiosInstance.put<T>(url, configs?.body, {
      headers: { ...configs?.headers, ...preparedHeaders },
      data: configs?.data,
    });

    return res.data;
  };

  public patch = async <T, P>(
    url: string,
    configs: PatchRequestConfig<P> = { isAuthorized: true }
  ): Promise<T> => {
    const { headers: preparedHeaders } = await this.prepareRequest(configs);
    const res = await this.axiosInstance.patch<T>(url, configs?.body, {
      headers: { ...configs?.headers, ...preparedHeaders },
    });

    return res.data;
  };

  public delete = async <T>(
    url: string,
    configs: DeleteRequestConfig = { isAuthorized: true }
  ): Promise<T> => {
    const { headers: preparedHeaders } = await this.prepareRequest(configs);
    const res = await this.axiosInstance.delete<T>(url, {
      ...configs,
      headers: { ...configs?.headers, ...preparedHeaders },
    });

    return res.data;
  };

  public setupAuth = (accessToken: string, refreshToken: string) => {
    if (accessToken) {
      this.accessToken = accessToken;
    }
    if (refreshToken) {
      this.refreshToken = refreshToken;
    }
  };

  public removeAuth = () => {
    this.accessToken = undefined;
    this.refreshToken = undefined;
  };

  public cancelRequests = () => {
    this.cancelToken.cancel();
  };

  public setupRefreshDelegate = (refreshAuthDelegate: RefreshAuthDelegate) => {
    this.refreshAuthDelegate = refreshAuthDelegate;
  };

  private setupErrorHandler = (errorHandler: IErrorHandler) => {
    this.errorInterceptor = this.axiosInstance.interceptors.response.use(
      (response) => response,
      (e) => {
        if (e?.response?.data?.Error) {
          errorHandler.blockInteraction(e.response.data.Error);
        } else if (!e?.status) {
          errorHandler.blockInteraction(
            'Network error! Retry when the connection is stable'
          );
        } else {
          recordError(e);
          errorHandler.blockInteraction('Something gone wrong!');
        }
        throw e;
      }
    );
  };

  private readonly prepareRequest = async (
    params: PrepareRequestParams
  ): Promise<Partial<AxiosRequestConfig>> => {
    if (params.isAuthorized && this.accessToken) {
      const tokenInfo = jwtDecode<JwtTokenInfo>(this.accessToken);
      const expirationDate = dayjs(tokenInfo.exp * 1000);
      const isExpired = expirationDate.isBefore(dayjs());
      if (this.refreshAuthDelegate && isExpired && this.refreshToken) {
        const res = await this.refreshAuthDelegate.refresh(this.refreshToken);
        const { AccessToken, RefreshToken } = res.AuthenticationResult;
        this.accessToken = AccessToken;
        if (RefreshToken) {
          this.refreshToken = RefreshToken;
        }
      } else if (isExpired) {
        // TODO: Throw catchable error
      }

      const headers: AxiosRequestHeaders = {};
      headers.Authorization = `Bearer ${this.accessToken}`;
      return {
        headers,
      };
    }
    return {};
  };
}
