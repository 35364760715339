import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { Config } from '../config';

type ProfileIconProps = {
  size?: number;
  color?: string;
};

export const ProfileIcon: React.FC<ProfileIconProps> = ({ color, size }) => {
  return (
    <Ionicons
      name="person-outline"
      size={size || 24}
      color={color || Config.colorTextPrimary}
    />
  );
};
