import { s } from 'react-native-wind';
import React, { useCallback } from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { ParagraphBold } from './ParagraphBold';

export type ButtonToggleOption<T> = {
  label: string;
  value: T;
};

type ButtonToggleProps<T> = {
  options: ButtonToggleOption<T>[];
  selectedOption: T;
  onOptionPress: (value: T) => void;
  containerStatus?: ViewStyle;
};

export const ButtonToggle = <T,>({
  options,
  selectedOption,
  onOptionPress,
  containerStatus,
}: ButtonToggleProps<T>) => {
  const optionPress = useCallback(
    (option: T) => {
      return () => {
        onOptionPress(option);
      };
    },
    [onOptionPress]
  );

  return (
    <View style={[s`bg-card p-2 w-full rounded-md flex-row`, containerStatus]}>
      {options.map((o, i) => {
        return (
          <TouchableOpacity
            key={i}
            onPress={optionPress(o.value)}
            style={s`rounded-md py-3 px-1 items-center flex-1 ${
              i === 0 ? '' : 'ml-2'
            } ${o.value === selectedOption ? 'bg-secondary' : ''}`}
          >
            <ParagraphBold
              style={s`${
                o.value === selectedOption
                  ? 'text-white'
                  : 'text-text-secondary'
              }`}
            >
              {o.label}
            </ParagraphBold>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
