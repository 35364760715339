import { useCallback, useEffect, useRef } from 'react';
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDispatch } from '../../redux/utils';
import {
  addFullPageLoader,
  removeFullPageLoader,
  setIsNoNetworkMoment,
} from '../../redux/general/general.slice';
import uuid from 'react-native-uuid';

export const InvalidationTag = {
  Company: 'company',
  MyDetails: 'my-details',
  MyTeams: 'my-teams',
  TeamMembers: 'team-members',
  TeamMember: 'team-member',
  CompanyUsersSearch: 'company-user-search',
  TeamFeedbacks: 'team-feedbacks',
  UserFeedbacks: 'user-feedbacks',
  Leaderboard: 'leaderboard',
  Badge: 'badge',
  TotalBadges: 'total-badges',
  LeaderboardPosition: 'leaderboard-position',
  Challenge: 'challenge',
  Challenges: 'challenges',
  Achievements: 'achievements',
  HarmoniaMessages: 'harmonia-messages',
  HarmoniaConfig: 'harmonia-config',
  RemoteConfig: 'remote-config',
};

export enum LoaderType {
  FullPage = 'full-page',
  Contextual = 'contextual',
  None = 'none',
}

type UseQuerySecondParam<T, S extends QueryKey, E = unknown> =
  | Omit<UseQueryOptions<T, E, T, S>, 'queryKey' | 'queryFn'>
  | undefined;

export const useAppQuery = <T, S extends QueryKey, E extends Error>(
  key: S,
  fetch: QueryFunction<T, S>,
  configs?: UseQuerySecondParam<T, S, E>,
  loader: LoaderType = LoaderType.FullPage
): UseQueryResult<T> => {
  const dispatch = useDispatch();

  const fetchWrap: QueryFunction<T, S> = useCallback(
    async (params) => {
      try {
        return await fetch(params);
      } catch (e: any) {
        if (!e?.status) {
          dispatch(setIsNoNetworkMoment(true));
        }
        throw e;
      }
    },
    [dispatch, fetch]
  );

  const queryObj = useQuery({ queryKey: key, queryFn: fetchWrap, ...configs });

  const loaderRef = useRef<string | null>(null);

  useEffect(() => {
    if (loader === LoaderType.FullPage) {
      if (loaderRef.current === null && queryObj.isLoading) {
        const loaderId = uuid.v4() as string;
        loaderRef.current = loaderId;
        dispatch(addFullPageLoader(loaderId));
      } else if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
        loaderRef.current = null;
      }
    }
  }, [dispatch, loader, queryObj.isLoading]);

  useEffect(() => {
    return () => {
      if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
      }
    };
  }, [dispatch]);

  return queryObj;
};

type UseMutationSecondParam<T, P> =
  | Omit<UseMutationOptions<T, unknown, P, unknown>, 'mutationFn'>
  | undefined;

export const useAppMutation = <T, P = void>(
  fetch: MutationFunction<T, P>,
  configs?: UseMutationSecondParam<T, P>,
  loader: LoaderType = LoaderType.FullPage
): UseMutationResult<T, unknown, P, unknown> => {
  const dispatch = useDispatch();

  const fetchWrap: MutationFunction<T, P> = useCallback(
    async (params) => {
      try {
        return await fetch(params);
      } catch (e: any) {
        if (!e?.status) {
          dispatch(setIsNoNetworkMoment(true));
        }
        throw e;
      }
    },
    [dispatch, fetch]
  );

  const mutation = useMutation({ mutationFn: fetchWrap, ...configs });

  const loaderRef = useRef<string | null>(null);

  useEffect(() => {
    if (loader === LoaderType.FullPage) {
      if (loaderRef.current === null && mutation.isPending) {
        const loaderId = uuid.v4() as string;
        loaderRef.current = loaderId;
        dispatch(addFullPageLoader(loaderId));
      } else if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
        loaderRef.current = null;
      }
    }
  }, [dispatch, loader, mutation.isPending]);

  useEffect(() => {
    return () => {
      if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
      }
    };
  }, [dispatch]);

  return mutation;
};

export const useClearCache = () => {
  const queryClient = useQueryClient();

  const clearCache = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  return clearCache;
};
