import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { s } from 'react-native-wind';
import {
  Layout,
  ParagraphBold,
  ParagraphSmall,
  Subtitle,
} from '../../components';
import { useBackNavbarAction } from '../../hooks';
import { useLanguage } from '../../locale';
import { Config } from '../../config';
import dayjs from 'dayjs';
import {
  useGetAchievements,
  useGetChallengesHistory,
} from '../../hooks/api/gamification.api';
import { useGetMyCompanies } from '../../hooks/api/company.api';

type AllChallengesScreenProps = {};

export const AllChallengesScreen: React.FC<AllChallengesScreenProps> = () => {
  const [t, , currentLocale] = useLanguage();
  const backAction = useBackNavbarAction();

  const { data: [selectedCompany] = [undefined] } = useGetMyCompanies(false);

  const { data: challenges = [] } = useGetChallengesHistory({
    companyId: selectedCompany?.id,
  });

  const { data: achievements = [] } = useGetAchievements({
    companyId: selectedCompany?.id,
  });

  const metrics = React.useMemo(() => {
    return achievements
      .flatMap(({ metrics }) => Object.entries(metrics))
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: acc[key]
            ? { ...acc[key], total: acc[key].total + value.amount }
            : { label: value.labels[currentLocale], total: value.amount },
        }),
        {} as Record<string, { label: string; total: number }>
      );
  }, [achievements, currentLocale]);

  return (
    <Layout
      leftAction={backAction}
      hasNestedScrollView
      isScrollView={false}
      containerStyle={s`flex-column`}
    >
      <Subtitle style={s`mb-4`}>{t('allChallenges.allResults')}</Subtitle>
      <View style={s`flex-row flex-wrap justify-around`}>
        {Object.values(metrics).map(({ label, total }) => (
          <View key={label} style={s`mb-6 w-5/12`}>
            <ParagraphBold style={s`font-medium mb-1 text-xs`}>
              {label}
            </ParagraphBold>
            <Subtitle
              style={s`bg-primary overflow-hidden py-1 rounded-xl text-2xl text-center text-white`}
            >
              {total}
            </Subtitle>
          </View>
        ))}
      </View>

      <Subtitle style={s`mb-5`}>
        {t('allChallenges.previousChallenges')}
      </Subtitle>
      <FlatList
        style={s`flex-1`}
        data={challenges}
        renderItem={({
          item: { id, targets, startsAt, endsAt, currentAmount, targetAmount },
        }) => {
          const percentage = (currentAmount / targetAmount) * 100;
          const completedPrizes = targets.filter(
            (t) => percentage >= t.minPercent
          );
          const quantity = completedPrizes.reduce(
            (pv, cv) => pv + cv.prizeQta,
            0
          );

          return (
            <View
              key={id}
              style={s`bg-card mb-3 py-2 flex-row border-primary border-2 rounded-xl items-center`}
            >
              <Image
                style={s`resize-contain h-14 w-14 mr-2.5`}
                source={{
                  uri: `${targets[0]?.prize.image}`,
                }}
              />

              <View>
                <ParagraphSmall style={s`text-xs mb-1`}>
                  <Text>{t('allChallenges.range')}: </Text>
                  <Text style={styles.bold}>
                    {dayjs(startsAt).format('DD/MM/YY')} -{' '}
                    {dayjs(endsAt).format('DD/MM/YY')}
                  </Text>
                </ParagraphSmall>

                <ParagraphSmall style={s`text-xs mb-1`}>
                  <Text>{t('allChallenges.prize')}: </Text>
                  <Text style={styles.bold}>
                    {targets[0]?.labels[currentLocale]}
                  </Text>
                </ParagraphSmall>

                <ParagraphSmall style={s`text-xs mb-1`}>
                  <Text>{t('allChallenges.quantity')}: </Text>
                  <Text style={styles.bold}>{quantity}</Text>
                </ParagraphSmall>
              </View>
            </View>
          );
        }}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: Config.fontSemibold,
  },
});
