// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea:focus,
input:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  max-width: 480px;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0px 10px 28px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

[role='tablist'] {
  padding-bottom: 2px;
}

[role='tablist'] a {
  padding-bottom: 3px;
  flex-direction: column;
}

[role='tablist'] a div {
  flex: 1;
}

[role='tablist'] a span {
  font-size: 10px;
  margin: 0;
}

[role='dialog'],
div[class^='r-bottom'],
div[class*=' r-bottom'] {
  max-width: 480px;
  width: 100%;
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: #9a9a9a !important;
}

:-moz-placeholder {
  color: #9a9a9a !important;
}

::-ms-input-placeholder {
  color: #9a9a9a !important;
}

::-webkit-input-placeholder {
  color: #9a9a9a !important;
}
`, "",{"version":3,"sources":["webpack://./src/portability/providers/GlobalStyleProvider/GlobalStyle.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;AACf;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;;;EAGE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["textarea:focus,\ninput:focus {\n  outline: none;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: inherit;\n}\n\nhtml {\n  box-sizing: border-box;\n}\n\nbody {\n  max-width: 480px;\n  margin: auto;\n  margin-bottom: 10px;\n  box-shadow: 0px 10px 28px 10px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n}\n\n[role='tablist'] {\n  padding-bottom: 2px;\n}\n\n[role='tablist'] a {\n  padding-bottom: 3px;\n  flex-direction: column;\n}\n\n[role='tablist'] a div {\n  flex: 1;\n}\n\n[role='tablist'] a span {\n  font-size: 10px;\n  margin: 0;\n}\n\n[role='dialog'],\ndiv[class^='r-bottom'],\ndiv[class*=' r-bottom'] {\n  max-width: 480px;\n  width: 100%;\n  margin: auto;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n::placeholder {\n  color: #9a9a9a !important;\n}\n\n:-moz-placeholder {\n  color: #9a9a9a !important;\n}\n\n::-ms-input-placeholder {\n  color: #9a9a9a !important;\n}\n\n::-webkit-input-placeholder {\n  color: #9a9a9a !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
