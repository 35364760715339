import { s } from 'react-native-wind';
import React, { useCallback, useEffect, useState } from 'react';
import { Picker } from '@react-native-picker/picker';

type SelectFieldProps<T> = {
  items: T[];
  selectedValue: T | undefined;
  displayFn: (val: T) => string;
  valueFn: (val: T) => string;
  onValueChange: (itemValue: T | undefined) => void;
};

export const SelectField = <T,>({
  displayFn,
  items,
  onValueChange,
  selectedValue,
  valueFn,
}: SelectFieldProps<T>) => {
  const [internalSelectedValue, setInternalSelectedValue] = useState<string>();

  useEffect(() => {
    setInternalSelectedValue(selectedValue && valueFn(selectedValue));
  }, [selectedValue, valueFn]);

  const onPickerValueChange = useCallback(
    (value: string | undefined) => {
      const changedValue = items.find((e) => value === valueFn(e));
      onValueChange?.(changedValue);
    },
    [items, onValueChange, valueFn]
  );

  return (
    <Picker
      selectedValue={internalSelectedValue}
      onValueChange={onPickerValueChange}
      itemStyle={s`text-sm`}
    >
      <Picker.Item label="Scegli utente..." value={undefined} />
      {items.map((e) => {
        const value = valueFn(e);
        const label = displayFn(e);
        return <Picker.Item key={value} label={label} value={value} />;
      })}
    </Picker>
  );
};
