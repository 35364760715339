import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
import { IconProps } from './types';

type TrophyEmptyIconProps = IconProps & SvgProps;

const TrophyEmptyIcon = ({
  width,
  height,
  size,
  ...props
}: TrophyEmptyIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#646D73"
        d="M359.801 429.3 256 399.3l-103.801 30c-.899 1.8-1.199 3.6-1.199 5.7v62h210v-62c0-2.1-.3-3.9-1.199-5.7Z"
      />
      <Path
        fill="#474F54"
        d="M361 497v-62c0-2.1-.3-3.9-1.199-5.7L256 399.3V497h105Z"
      />
      <Path
        fill="#C2C2C2"
        d="M497 30h-76.645c.132-5.067.645-9.84.645-15 0-8.401-6.599-15-15-15H106c-8.401 0-15 6.599-15 15 0 5.16.513 9.933.643 15H15C6.709 30 0 36.709 0 45v37.998c0 83.754 67.092 151.791 149.773 156.171 17.225 23.716 37.668 41.316 60.927 51.231-3.6 72.9-45.3 123.6-55.199 134.101C154 425.7 152.8 427.5 152.2 429.3h207.601c-.601-1.8-1.8-3.6-3.301-4.799C346.3 414 304.9 363.6 301.3 290.4c23.264-9.917 43.83-27.521 61.069-51.246C444.979 234.703 512 166.705 512 82.998V45c0-8.291-6.709-15-15-15ZM30 82.998V60h62.93c3.893 49.578 14.644 102.086 37.24 146.708C73.53 193.953 30 143.443 30 82.998Zm452 0c0 60.414-43.491 110.9-100.089 123.684C404.328 162.061 415.139 110.061 419.07 60H482v22.998Z"
      />
      <Path
        fill="#A3A3A3"
        d="M497 30h-76.645c.132-5.067.645-9.84.645-15 0-8.401-6.599-15-15-15H256v429.3h103.801c-.601-1.8-1.8-3.6-3.301-4.799C346.3 414 304.9 363.6 301.3 290.4c23.264-9.917 43.83-27.521 61.069-51.246C444.979 234.703 512 166.705 512 82.998V45c0-8.291-6.709-15-15-15Zm-15 52.998c0 60.414-43.491 110.9-100.089 123.684C404.33 162.061 415.141 110.061 419.07 60H482v22.998Z"
      />
      <Path
        fill="#AAA"
        d="M279.936 190.796 256 178.359l-23.936 12.437c-5.01 2.578-11.133 2.153-15.732-1.172a14.98 14.98 0 0 1-5.977-14.59l4.395-26.646-19.189-18.94c-4.177-4.072-5.454-10.109-3.75-15.322a15.028 15.028 0 0 1 12.041-10.195l26.66-4.014 12.07-24.126c5.098-10.166 21.738-10.166 26.836 0l12.07 24.126 26.66 4.014a15.032 15.032 0 0 1 12.041 10.195 14.947 14.947 0 0 1-3.75 15.322l-19.189 18.94 4.395 26.646a14.978 14.978 0 0 1-5.977 14.59c-4.569 3.311-10.681 3.8-15.732 1.172Z"
      />
      <Path
        fill="#747474"
        d="M279.936 190.796c5.052 2.628 11.164 2.139 15.732-1.172a14.98 14.98 0 0 0 5.977-14.59l-4.395-26.646 19.189-18.94a14.947 14.947 0 0 0 3.75-15.322 15.028 15.028 0 0 0-12.041-10.195l-26.66-4.014-12.07-24.126c-2.549-5.083-7.983-7.625-13.418-7.625v110.193l23.936 12.437Z"
      />
      <Path
        fill="#474F54"
        d="M376 512H136c-8.291 0-15-6.709-15-15s6.709-15 15-15h240c8.291 0 15 6.709 15 15s-6.709 15-15 15Z"
      />
      <Path
        fill="#32393F"
        d="M376 482H256v30h120c8.291 0 15-6.709 15-15s-6.709-15-15-15Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h512v512H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default TrophyEmptyIcon;
