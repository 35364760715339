import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

export const useBack = () => {
  const navigation = useNavigation();

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  }, [navigation]);

  return goBack;
};
