import * as React from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ParagraphSmall, Subtitle } from '../../../components';
import { ArrowRightIcon } from 'react-native-heroicons/outline';
import { useLanguage } from '../../../locale';
import { s } from 'react-native-wind';
import { Config } from '../../../config';
import { useCompanySectionController } from '../controllers/useCompanySection.controller';
import { getCorrectMedalImage } from '../utils/getCorrectMedalImage';
import NoChallengeSection from './NoChallengeSection';
import { useNavigation } from '@react-navigation/native';
import { AppNavigation } from '../../../navigation/core/types';
import { GAME_SCREEN } from '../../../navigation/authenticatedWithBottomTab/authenticated.types';
import { ALL_CHALLENGES_SCREEN } from '../../../navigation/authenticated/authenticated.types';

type CompanyGamificationSectionProps = {};

const CompanyGamificationSection: React.FC<
  CompanyGamificationSectionProps
> = () => {
  const [t] = useLanguage();
  const navigation = useNavigation<AppNavigation<typeof GAME_SCREEN>>();

  const {
    challengePrizes,
    completedPercentage,
    completedPrizes,
    currentChallengePoints,
    currentMetrics,
    nextPrizePoints,
    challengeExist,
    isLoading,
    historyExist,
    leftDays,
  } = useCompanySectionController();

  const allChallengesButton = React.useMemo(() => {
    return historyExist ? (
      <TouchableOpacity
        style={s`flex-row items-center justify-end`}
        onPress={() => navigation.navigate(ALL_CHALLENGES_SCREEN)}
      >
        <ParagraphSmall style={s`text-primary mr-1`}>
          {t('gamification.companySection.allProgresses')}
        </ParagraphSmall>
        <ArrowRightIcon color={Config.colorPrimary} />
      </TouchableOpacity>
    ) : null;
  }, [historyExist, navigation, t]);

  if (isLoading) return null;

  return challengeExist ? (
    <ScrollView
      style={styles.scrollView}
      contentContainerStyle={s`px-page-padding`}
    >
      <View
        style={s`flex-row ${
          !!leftDays ? 'justify-between' : 'justify-end'
        } items-center`}
      >
        <View style={s`items-center`}>
          <ParagraphSmall>
            {!!leftDays ? t('gamification.companySection.daysLeft') + ': ' : ''}
            <Text style={[s`text-base`, styles.bold]}>
              {!!leftDays
                ? leftDays
                : t('gamification.companySection.challengeExpired')}
            </Text>
          </ParagraphSmall>
        </View>
        {allChallengesButton}
      </View>
      <View style={s`mt-4 flex-row items-center mt-11`}>
        <View style={s`flex-1`}>
          <View style={s`w-full h-2 border border-primary rounded-full`}>
            <View
              style={[
                s`h-full bg-primary`,
                { width: `${completedPercentage}%` },
              ]}
            />
            {challengePrizes.map((p) => {
              const isCompleted = completedPercentage >= p.minPercent;
              const MedalImage = getCorrectMedalImage(p.icon);
              return (
                <View
                  key={`indicator-${p.minPercent}`}
                  style={[
                    s`absolute`,
                    {
                      left: `${p.minPercent - 2}%`,
                      top: '-80%',
                    },
                  ]}
                >
                  <MedalImage size={24} style={styles.medal} />
                  <View
                    style={[
                      s`h-4 w-2 ${
                        isCompleted
                          ? 'bg-secondary'
                          : 'bg-light-grey border border-text-secondary'
                      } rounded-sm absolute`,
                    ]}
                  />
                </View>
              );
            })}
          </View>
          {currentChallengePoints !== null && nextPrizePoints !== null ? (
            <View style={s`mt-2`}>
              {currentChallengePoints >= nextPrizePoints ? (
                <ParagraphSmall>
                  {t('gamification.companySection.unlockAllPrizes')}
                </ParagraphSmall>
              ) : (
                <ParagraphSmall>
                  {t('gamification.companySection.nextPrice', {
                    currentPoints: currentChallengePoints,
                    targetPoints: nextPrizePoints,
                  })}
                </ParagraphSmall>
              )}
            </View>
          ) : null}
          {challengePrizes ? (
            <>
              <Subtitle style={s`mt-4 mb-1`}>
                {t('gamification.companySection.prizes')}
              </Subtitle>
              {challengePrizes.map((p) => {
                const MedalImage = getCorrectMedalImage(p.icon);
                const isCompleted = completedPrizes?.find(
                  (cp) => p.minPercent === cp.minPercent
                );

                return (
                  <View
                    key={`prize-${p.minPercent}`}
                    style={s`mt-3 ${
                      isCompleted ? 'bg-primary' : 'bg-card'
                    } rounded-xl flex-row items-center p-2`}
                  >
                    <Image
                      style={s`resize-contain w-10 h-10`}
                      source={{
                        uri: `${p.prize.image}`,
                      }}
                    />
                    <View style={s`flex-1 mx-2`}>
                      <ParagraphSmall
                        style={[
                          s`${isCompleted ? 'text-white' : ''}`,
                          styles.bold,
                        ]}
                      >
                        {p.labels.it}
                      </ParagraphSmall>
                      <ParagraphSmall
                        style={s`${isCompleted ? 'text-white' : ''}`}
                      >
                        {t('gamification.companySection.quantity')}:{' '}
                        {p.prizeQta}
                      </ParagraphSmall>
                    </View>
                    <MedalImage size={24} />
                  </View>
                );
              })}
            </>
          ) : null}
        </View>
      </View>
      {currentMetrics && Object.keys(currentMetrics).length > 0 ? (
        <>
          <Subtitle style={s`mt-4`}>
            {t('gamification.companySection.results')}
          </Subtitle>
          <View style={s`flex-row flex-wrap justify-around my-4`}>
            {Object.keys(currentMetrics).map((metricKey) => {
              const metric = currentMetrics[metricKey];

              return (
                <View key={metricKey} style={s`w-5/12`}>
                  <ParagraphSmall style={[s`text-xs`, styles.bold]}>
                    {metric.labels.it}
                  </ParagraphSmall>
                  <View style={s`py-1 px-2 bg-primary w-full mt-1 rounded-xl`}>
                    <Subtitle style={s`text-center text-white`}>
                      {metric.amount}
                    </Subtitle>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      ) : null}
    </ScrollView>
  ) : (
    <>
      {allChallengesButton}
      <NoChallengeSection />
    </>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: Config.fontSemibold,
  },
  medal: {
    left: -8,
    top: -32,
  },
  scrollView: {
    marginHorizontal: -27,
    flex: 1,
    marginBottom: -27,
  },
});

export default CompanyGamificationSection;
