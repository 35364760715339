import React from 'react';
import { useDispatch, useSelector } from '../../../redux/utils';
import { BottomSheetRef } from '../../../components';
import { Feedback } from '../../../api/feedback/feedback.models';
import {
  isSummarySelectedSelector,
  selectedTeamSelector,
} from '../../../redux/general/general.selectors';
import { useGetMyTeams } from '../../../hooks/api/team.api';
import { TeamStatus } from '../../../api/team/team.models';
import { useCategories } from '../../../hooks/useCategories';
import {
  setIsSummary,
  setSelectedTeam,
} from '../../../redux/general/general.slice';
import {
  useGetFeedbacks,
  useGetUserFeedbacks,
} from '../../../hooks/api/feedback.api';
import dayjs from 'dayjs';
import { StorageKeys } from '../../../services/StorageProvider/IStorageProvider';
import { useStorageProvider } from '../../../context/StorageProviderContext/useStorageProvider';
import { useNavigation } from '@react-navigation/native';
import { SLACK_INTEGRATION_SCREEN } from '../../../navigation/authenticated/authenticated.types';
import { AppNavigation } from '../../../navigation/core/types';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { useGetHarmoniaConfig } from '../../../hooks/api/harmonia.api';
import useUserData from '../../../hooks/useUserData';

const FROM_DATE = dayjs().subtract(13, 'days').format('YYYY-MM-DD');
const TO_DATE = dayjs().format('YYYY-MM-DD');

const useStatsController = () => {
  const dispatch = useDispatch();
  const storage = useStorageProvider();
  const changeTeamBottomSheetRef = React.useRef<BottomSheetRef>(null);
  const feedbackDetailsBottomSheet = React.useRef<BottomSheetRef>(null);
  const [feedbacksDetails, setFeedbacksDetails] = React.useState<Feedback[]>();
  const { navigate } =
    useNavigation<AppNavigation<typeof SLACK_INTEGRATION_SCREEN>>();
  const selectedTeam = useSelector(selectedTeamSelector);
  const isSummarySelected = useSelector(isSummarySelectedSelector);

  const { user: myDetails, company } = useUserData();
  const { data: teams } = useGetMyTeams(TeamStatus.Active);
  const categories = useCategories();
  const [FeatureFlags] = useFeatureFlags();
  const { data: harmoniaConfig } = useGetHarmoniaConfig(company?.id, !!company);

  React.useEffect(() => {
    if (teams && teams.length === 1) {
      dispatch(setSelectedTeam(teams[0]));
      dispatch(setIsSummary(false));
    }
  }, [dispatch, teams]);

  const { data: feedbacks = [], isLoading: isUserFeedbacksLoading } =
    useGetUserFeedbacks(
      {
        from: FROM_DATE,
        to: TO_DATE,
      },
      isSummarySelected && teams && teams.length > 1
    );

  const { data: teamFeedbacks = [], isLoading: isUserTeamFeedbackLoading } =
    useGetFeedbacks(
      {
        from: FROM_DATE,
        to: TO_DATE,
        userId: myDetails?.id,
        teamId: selectedTeam?.id,
      },
      Boolean(
        selectedTeam && (!isSummarySelected || (teams && teams.length === 1))
      )
    );

  const isNoDataVisible =
    (isSummarySelected && feedbacks.length === 0 && !isUserFeedbacksLoading) ||
    (selectedTeam &&
      (!isSummarySelected || (teams && teams.length === 1)) &&
      teamFeedbacks.length === 0 &&
      !isUserTeamFeedbackLoading);

  const openChangeTeamBottomSheet = React.useCallback(() => {
    changeTeamBottomSheetRef.current?.expand();
  }, []);

  const showFeedbackDetails = React.useCallback((feedbacks: Feedback[]) => {
    setFeedbacksDetails(feedbacks);
    feedbackDetailsBottomSheet.current?.expand();
  }, []);

  const feedbackData = React.useMemo(() => {
    if (isSummarySelected) {
      return feedbacks;
    } else {
      return teamFeedbacks;
    }
  }, [feedbacks, isSummarySelected, teamFeedbacks]);

  React.useEffect(() => {
    storage.getItem(StorageKeys.SHOULD_REDIRECT_TO_SLACK).then((b) => {
      if (b === 'true') {
        storage
          .setItem(StorageKeys.SHOULD_REDIRECT_TO_SLACK, 'false')
          .then(() => navigate(SLACK_INTEGRATION_SCREEN));
      }
    });
  }, [navigate, storage]);

  return {
    changeTeamBottomSheetRef,
    feedbackDetailsBottomSheet,
    feedbacksDetails,
    isNoDataVisible,
    openChangeTeamBottomSheet,
    showFeedbackDetails,
    feedbackData,
    categories,
    myDetails,
    isSummarySelected,
    teams,
    selectedTeam,
    isHarmoniaActive: FeatureFlags.Harmonia,
    unseenMessages: harmoniaConfig?.unseenMessages,
  };
};

export default useStatsController;
