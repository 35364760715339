import React, { useEffect } from 'react';
import { Layout } from '../../components';
import { useRoute } from '@react-navigation/native';
import { useSubscribeSlack } from '../../hooks/api/slack.api';
import useUserData from '../../hooks/useUserData';
import SlackRequestAccess from './components/SlackRequestAccess';
import { useFullPageLoader } from '../../context/Loader/useFullPageLoader';
import SlackErrorFallback from './components/SlackErrorFallback';
import SlackSuccessFallback from './components/SlackSuccessFallback';

const SlackIntegrationScreen = () => {
  const { params } = useRoute();
  const { company, isReady } = useUserData(true);
  const { code } = (params ?? {}) as { code?: string };
  const [openLoader, closeLoader] = useFullPageLoader();
  const {
    mutateAsync: subscribeToSlack,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSubscribeSlack();

  useEffect(() => {
    if (code && isReady) {
      openLoader();
      subscribeToSlack({ code, companyId: company?.id ?? '' });
    }
  }, [code, isReady, company, subscribeToSlack, openLoader]);

  useEffect(() => {
    if (!isLoading) closeLoader();
  }, [isLoading, closeLoader]);

  return (
    <Layout>
      {(!code || isLoading) && <SlackRequestAccess />}
      {isSuccess && <SlackSuccessFallback />}
      {isError && <SlackErrorFallback error={error?.toString() ?? ''} />}
    </Layout>
  );
};

export default SlackIntegrationScreen;
